
a {
  color: #666;
  cursor: pointer;
}

body {
  position: fixed;
  overflow: hidden;
}

.cellBal { color: white; background-color: #2196f3b8; }
.cellGood { color: white; background-color: #4caf50b8; }
.cellWarn { color: black; background-color: #ffeb3bb8; }
.cellDanger { color: white; background-color: #f44336b8; }

.stattitle { margin-top: 10px; }

.selectedGraph::before { content: "> "; color: #2a90cb;}
.selectedGraph { font-weight: bold;  }
.selectedGraph a { color: #2a90cb !important;}

.selectedBMS a { color: #2a90cb !important;}


.BMSModuleID { vertical-align: top; padding: 0px 5px; }
.BMSCellTable td { text-align: center; vertical-align: center; padding: 0px 7px; line-height: 16px; font-size: 12px; }
.BMSPackTable td, .BMSPackTable th { text-align: right; padding: 0px 8px; }

.BMSCellTable { table-layout: fixed; width: 100% }
.BMSPackTable { width: 100% }

.SonoffTable { table-layout: fixed; width: 100%; }
.SonoffTable td, .SonoffTable th { text-align: right; padding: 0px 6px; }
.SonoffTable td:first-child, .SonoffTable th:first-child { text-align:left; width: 130px; }

.chartIntervals { margin-top: -10px; margin-bottom: 10px; }

.stattable { width: 100%; }
.stattable td:first-child { width: 140px; }
.stattable td:nth-child(2) { width: 70px; text-align: right; }

.liveunit { color: rgb(134, 134, 134); font-size: smaller; }

.liveTree a, .BMSPackTable a { color: rgb(255,255,255,0.85) ; }

header.ant-layout-header { padding: 0 0px !important; }

.tagBox {
  width: 300px;
  margin-top:20px;
  text-align: center;
}

.chartLoadBox { 
  height: 235px;
  width: 100%;
  padding-top:100px;
  text-align: center;
}

.site-layout-content {
  min-height: 80vh;
  padding-top: 24px;
  padding-bottom: 24px;
 /* background: #222;*/
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

main.ant-layout-content {
  margin: 0px 10px;
}

.agoStale, .agoStale td a { color: #666; }
.agoFresh, .agoFresh td a { color: rgb(255,255,255,0.85); }

.stattitle { display: none; }
.charttop { margin: 0px -5px 0px -5px; }
.chartBox { padding: 0px; margin: 0px; }
.statleft { overflow: auto;
            height: calc(100vh - 360px);
            padding: 10px; }

header.ant-layout-header { padding: 0 25px; }

@media only screen and (min-width: 600px) {
        .charttop { float: right; width: calc(100% - 310px); margin: 0px }
        .stattable { margin: 0px; }
        .chartIntervals { margin-top: 0px; margin-bottom: -10px; margin-left: 25px; }
        .stattitle { display: block; }
        .statleft { overflow: hidden; height: auto; }
        header.ant-layout-header { padding: 0 50px; }
}


@media only screen and (min-width: 600px) {
  main.ant-layout-content {
    margin: 0px 20px;
  }
  .chartBox { margin: 0px; padding-top: 50px; padding-left: 20px; }

}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}